import { FuturesMarketAsset, type SynthAssetKeysV3 } from '@kwenta/sdk/types'
import type { StaticImageData } from 'next/image'

import SNXBTC from '../../public/images/png/currencies/snxbtc.png'
import SNXETH from '../../public/images/png/currencies/snxeth.png'
import SNXLINK from '../../public/images/png/currencies/snxlink.png'
import SNXOP from '../../public/images/png/currencies/snxop.png'
import SNXUSD from '../../public/images/png/currencies/snxusd.png'

const SYN_MARKET_ASSETS_SITE = 'https://assets.synthetix.io/markets'
export const SYNTH_ICONS: Record<
	FuturesMarketAsset | SynthAssetKeysV3 | string,
	StaticImageData | string
> = {
	[FuturesMarketAsset.AAVE]: `${SYN_MARKET_ASSETS_SITE}/AAVE.svg`,
	[FuturesMarketAsset.ADA]: `${SYN_MARKET_ASSETS_SITE}/ADA.svg`,
	[FuturesMarketAsset.ALGO]: `${SYN_MARKET_ASSETS_SITE}/ALGO.svg`,
	[FuturesMarketAsset.ANKR]: `${SYN_MARKET_ASSETS_SITE}/ANKR.svg`,
	[FuturesMarketAsset.APE]: `${SYN_MARKET_ASSETS_SITE}/APE.svg`,
	[FuturesMarketAsset.APT]: `${SYN_MARKET_ASSETS_SITE}/APT.svg`,
	[FuturesMarketAsset.ARB]: `${SYN_MARKET_ASSETS_SITE}/ARB.svg`,
	[FuturesMarketAsset.ARKM]: `${SYN_MARKET_ASSETS_SITE}/ARKM.svg`,
	[FuturesMarketAsset.ATOM]: `${SYN_MARKET_ASSETS_SITE}/ATOM.svg`,
	[FuturesMarketAsset.AUD]: `${SYN_MARKET_ASSETS_SITE}/AUD.svg`,
	[FuturesMarketAsset.AVAX]: `${SYN_MARKET_ASSETS_SITE}/AVAX.svg`,
	[FuturesMarketAsset.AXL]: `${SYN_MARKET_ASSETS_SITE}/AXL.svg`,
	[FuturesMarketAsset.AXS]: `${SYN_MARKET_ASSETS_SITE}/AXS.svg`,
	[FuturesMarketAsset.BAL]: `${SYN_MARKET_ASSETS_SITE}/BAL.svg`,
	[FuturesMarketAsset.BCH]: `${SYN_MARKET_ASSETS_SITE}/BCH.svg`,
	[FuturesMarketAsset.BLUR]: `${SYN_MARKET_ASSETS_SITE}/BLUR.svg`,
	[FuturesMarketAsset.BNB]: `${SYN_MARKET_ASSETS_SITE}/BNB.svg`,
	[FuturesMarketAsset.BOME]: `${SYN_MARKET_ASSETS_SITE}/BOME.svg`,
	[FuturesMarketAsset.BONK]: `${SYN_MARKET_ASSETS_SITE}/BONK.svg`,
	[FuturesMarketAsset.sBTC]: `${SYN_MARKET_ASSETS_SITE}/BTC.svg`,
	[FuturesMarketAsset.CELO]: `${SYN_MARKET_ASSETS_SITE}/CELO.svg`,
	[FuturesMarketAsset.COMP]: `${SYN_MARKET_ASSETS_SITE}/COMP.svg`,
	[FuturesMarketAsset.CRV]: `${SYN_MARKET_ASSETS_SITE}/CRV.svg`,
	[FuturesMarketAsset.CVX]: `${SYN_MARKET_ASSETS_SITE}/CVX.svg`,
	[FuturesMarketAsset.DOGE]: `${SYN_MARKET_ASSETS_SITE}/DOGE.svg`,
	[FuturesMarketAsset.DOT]: `${SYN_MARKET_ASSETS_SITE}/DOT.svg`,
	[FuturesMarketAsset.DYDX]: `${SYN_MARKET_ASSETS_SITE}/DYDX.svg`,
	[FuturesMarketAsset.ENA]: `${SYN_MARKET_ASSETS_SITE}/ENA.svg`,
	[FuturesMarketAsset.ENJ]: `${SYN_MARKET_ASSETS_SITE}/ENJ.svg`,
	[FuturesMarketAsset.EOS]: `${SYN_MARKET_ASSETS_SITE}/EOS.svg`,
	[FuturesMarketAsset.ETC]: `${SYN_MARKET_ASSETS_SITE}/ETC.svg`,
	[FuturesMarketAsset.sETH]: `${SYN_MARKET_ASSETS_SITE}/ETH.svg`,
	[FuturesMarketAsset.ETHBTC]: `${SYN_MARKET_ASSETS_SITE}/ETHBTC.svg`,
	[FuturesMarketAsset.SOLETH]: '/images/svg/currencies/SOLETH.svg',
	[FuturesMarketAsset.ETHFI]: `${SYN_MARKET_ASSETS_SITE}/ETHFI.svg`,
	[FuturesMarketAsset.EUR]: `${SYN_MARKET_ASSETS_SITE}/EUR.svg`,
	[FuturesMarketAsset.FET]: `${SYN_MARKET_ASSETS_SITE}/FET.svg`,
	[FuturesMarketAsset.FIL]: `${SYN_MARKET_ASSETS_SITE}/FIL.svg`,
	[FuturesMarketAsset.FLOKI]: `${SYN_MARKET_ASSETS_SITE}/FLOKI.svg`,
	[FuturesMarketAsset.FLOW]: `${SYN_MARKET_ASSETS_SITE}/FLOW.svg`,
	[FuturesMarketAsset.FTM]: `${SYN_MARKET_ASSETS_SITE}/FTM.svg`,
	[FuturesMarketAsset.FXS]: `${SYN_MARKET_ASSETS_SITE}/FXS.svg`,
	[FuturesMarketAsset.GALA]: `${SYN_MARKET_ASSETS_SITE}/GALA.svg`,
	[FuturesMarketAsset.GMX]: `${SYN_MARKET_ASSETS_SITE}/GMX.svg`,
	[FuturesMarketAsset.GRT]: `${SYN_MARKET_ASSETS_SITE}/GRT.svg`,
	[FuturesMarketAsset.ICP]: `${SYN_MARKET_ASSETS_SITE}/ICP.svg`,
	[FuturesMarketAsset.IMX]: `${SYN_MARKET_ASSETS_SITE}/IMX.svg`,
	[FuturesMarketAsset.INJ]: `${SYN_MARKET_ASSETS_SITE}/INJ.svg`,
	[FuturesMarketAsset.JTO]: `${SYN_MARKET_ASSETS_SITE}/JTO.svg`,
	[FuturesMarketAsset.JUP]: `${SYN_MARKET_ASSETS_SITE}/JUP.svg`,
	[FuturesMarketAsset.KNC]: `${SYN_MARKET_ASSETS_SITE}/KNC.svg`,
	[FuturesMarketAsset.LINK]: `${SYN_MARKET_ASSETS_SITE}/LINK.svg`,
	[FuturesMarketAsset.LDO]: `${SYN_MARKET_ASSETS_SITE}/LDO.svg`,
	[FuturesMarketAsset.LTC]: '/images/svg/currencies/LTC.svg',
	[FuturesMarketAsset.POL]: '/images/svg/currencies/POL.svg',
	[FuturesMarketAsset.MAV]: `${SYN_MARKET_ASSETS_SITE}/MAV.svg`,
	[FuturesMarketAsset.MEME]: `${SYN_MARKET_ASSETS_SITE}/MEME.svg`,
	[FuturesMarketAsset.MKR]: `${SYN_MARKET_ASSETS_SITE}/MKR.svg`,
	[FuturesMarketAsset.NEAR]: `${SYN_MARKET_ASSETS_SITE}/NEAR.svg`,
	[FuturesMarketAsset.ONEINCH]: `${SYN_MARKET_ASSETS_SITE}/ONEINCH.svg`,
	[FuturesMarketAsset.ONE]: `${SYN_MARKET_ASSETS_SITE}/ONE.svg`,
	[FuturesMarketAsset.OP]: `${SYN_MARKET_ASSETS_SITE}/OP.svg`,
	[FuturesMarketAsset.ORDI]: `${SYN_MARKET_ASSETS_SITE}/ORDI.svg`,
	[FuturesMarketAsset.PENDLE]: `${SYN_MARKET_ASSETS_SITE}/PENDLE.svg`,
	[FuturesMarketAsset.PEPE]: `${SYN_MARKET_ASSETS_SITE}/PEPE.svg`,
	[FuturesMarketAsset.PERP]: `${SYN_MARKET_ASSETS_SITE}/PERP.svg`,
	[FuturesMarketAsset.PYTH]: `${SYN_MARKET_ASSETS_SITE}/PYTH.svg`,
	[FuturesMarketAsset.RNDR]: `${SYN_MARKET_ASSETS_SITE}/RNDR.svg`,
	[FuturesMarketAsset.RENDER]: '/images/svg/currencies/RENDER.svg',
	[FuturesMarketAsset.RPL]: `${SYN_MARKET_ASSETS_SITE}/RPL.svg`,
	[FuturesMarketAsset.RUNE]: `${SYN_MARKET_ASSETS_SITE}/RUNE.svg`,
	[FuturesMarketAsset.SHIB]: `${SYN_MARKET_ASSETS_SITE}/SHIB.svg`,
	[FuturesMarketAsset.SOL]: `${SYN_MARKET_ASSETS_SITE}/SOL.svg`,
	[FuturesMarketAsset.STETHETH]: `${SYN_MARKET_ASSETS_SITE}/STETHETH.svg`,
	[FuturesMarketAsset.STETH]: `${SYN_MARKET_ASSETS_SITE}/STETH.svg`,
	[FuturesMarketAsset.STRK]: `${SYN_MARKET_ASSETS_SITE}/STRK.svg`,
	[FuturesMarketAsset.STX]: `${SYN_MARKET_ASSETS_SITE}/STX.svg`,
	[FuturesMarketAsset.SUI]: `${SYN_MARKET_ASSETS_SITE}/SUI.svg`,
	[FuturesMarketAsset.SUSHI]: `${SYN_MARKET_ASSETS_SITE}/SUSHI.svg`,
	[FuturesMarketAsset.TAO]: `${SYN_MARKET_ASSETS_SITE}/TAO.svg`,
	[FuturesMarketAsset.TIA]: `${SYN_MARKET_ASSETS_SITE}/TIA.svg`,
	[FuturesMarketAsset.TON]: `${SYN_MARKET_ASSETS_SITE}/TON.svg`,
	[FuturesMarketAsset.TRB]: `${SYN_MARKET_ASSETS_SITE}/TRB.svg`,
	[FuturesMarketAsset.TRX]: `${SYN_MARKET_ASSETS_SITE}/TRX.svg`,
	[FuturesMarketAsset.UMA]: `${SYN_MARKET_ASSETS_SITE}/UMA.svg`,
	[FuturesMarketAsset.UNI]: `${SYN_MARKET_ASSETS_SITE}/UNI.svg`,
	[FuturesMarketAsset.USDT]: `${SYN_MARKET_ASSETS_SITE}/USDT.svg`,
	[FuturesMarketAsset.WLD]: `${SYN_MARKET_ASSETS_SITE}/WLD.svg`,
	[FuturesMarketAsset.XAU]: `${SYN_MARKET_ASSETS_SITE}/XAU.svg`,
	[FuturesMarketAsset.XAG]: `${SYN_MARKET_ASSETS_SITE}/XAG.svg`,
	[FuturesMarketAsset.XLM]: `${SYN_MARKET_ASSETS_SITE}/XLM.svg`,
	[FuturesMarketAsset.XMR]: `${SYN_MARKET_ASSETS_SITE}/XMR.svg`,
	[FuturesMarketAsset.XRP]: `${SYN_MARKET_ASSETS_SITE}/XRP.svg`,
	[FuturesMarketAsset.XTZ]: `${SYN_MARKET_ASSETS_SITE}/XTZ.svg`,
	[FuturesMarketAsset.YFI]: `${SYN_MARKET_ASSETS_SITE}/YFI.svg`,
	[FuturesMarketAsset.ZEC]: `${SYN_MARKET_ASSETS_SITE}/ZEC.svg`,
	[FuturesMarketAsset.ZIL]: `${SYN_MARKET_ASSETS_SITE}/ZIL.svg`,
	[FuturesMarketAsset.ZRX]: `${SYN_MARKET_ASSETS_SITE}/ZRX.svg`,
	[FuturesMarketAsset.SNX]: `${SYN_MARKET_ASSETS_SITE}/SNXDark.svg`,
	[FuturesMarketAsset.WIF]: `${SYN_MARKET_ASSETS_SITE}/WIF.svg`,
	[FuturesMarketAsset.W]: `${SYN_MARKET_ASSETS_SITE}/W.svg`,
	[FuturesMarketAsset.SEI]: `${SYN_MARKET_ASSETS_SITE}/SEI.svg`,
	[FuturesMarketAsset.POPCAT]: '/images/svg/currencies/POPCAT.svg',
	[FuturesMarketAsset.AERO]: '/images/svg/currencies/AERO.svg',
	[FuturesMarketAsset.EIGEN]: '/images/svg/currencies/EIGEN.svg',
	[FuturesMarketAsset.IO]: '/images/svg/currencies/IO.svg',
	[FuturesMarketAsset.MEW]: '/images/svg/currencies/MEW.svg',
	[FuturesMarketAsset.NOT]: '/images/svg/currencies/NOT.svg',
	[FuturesMarketAsset.PEOPLE]: '/images/svg/currencies/PEOPLE.svg',
	[FuturesMarketAsset.SATS]: '/images/svg/currencies/SATS.svg',
	[FuturesMarketAsset.ZRO]: '/images/svg/currencies/ZRO.svg',
	[FuturesMarketAsset.GOAT]: '/images/svg/currencies/GOAT.svg',
	[FuturesMarketAsset.CAT]: '/images/svg/currencies/CAT.svg',
	[FuturesMarketAsset.MOODENG]: '/images/svg/currencies/MOODENG.svg',
	[FuturesMarketAsset.SAFE]: '/images/svg/currencies/SAFE.svg',
	[FuturesMarketAsset.PNUT]: '/images/svg/currencies/PNUT.svg',
	[FuturesMarketAsset.DEGEN]: '/images/svg/currencies/DEGEN.svg',
	[FuturesMarketAsset.SLERF]: '/images/svg/currencies/SLERF.svg',
	[FuturesMarketAsset.MORPHO]: '/images/svg/currencies/MORPHO.svg',
	[FuturesMarketAsset.VIRTUAL]: '/images/svg/currencies/VIRTUAL.svg',
	[FuturesMarketAsset.CHILLGUY]: '/images/svg/currencies/CHILLGUY.svg',
	[FuturesMarketAsset.PENGU]: '/images/svg/currencies/PENGU.svg',
	[FuturesMarketAsset.FARTCOIN]: '/images/svg/currencies/FARTCOIN.svg',
	[FuturesMarketAsset.KMNO]: '/images/svg/currencies/KMNO.svg',
	[FuturesMarketAsset.TRUMP]: '/images/svg/currencies/TRUMP.svg',
	[FuturesMarketAsset.MELANIA]: '/images/svg/currencies/MELANIA.svg',
	[FuturesMarketAsset.S]: '/images/svg/currencies/S.svg',
	[FuturesMarketAsset.VELO]: '/images/svg/currencies/VELO.svg',
	[FuturesMarketAsset.VVV]: '/images/svg/currencies/VVV.svg',
	[FuturesMarketAsset.BERA]: '/images/svg/currencies/BERA.svg',
	sAAVE: `${SYN_MARKET_ASSETS_SITE}/AAVE.svg`,
	sADA: `${SYN_MARKET_ASSETS_SITE}/ADA.svg`,
	sAPE: `${SYN_MARKET_ASSETS_SITE}/APE.svg`,
	sARB: `${SYN_MARKET_ASSETS_SITE}/ARB.svg`,
	sAPT: `${SYN_MARKET_ASSETS_SITE}/APT.svg`,
	sATOM: `${SYN_MARKET_ASSETS_SITE}/ATOM.svg`,
	sAUD: `${SYN_MARKET_ASSETS_SITE}/AUD.svg`,
	sAVAX: `${SYN_MARKET_ASSETS_SITE}/AVAX.svg`,
	sAXS: `${SYN_MARKET_ASSETS_SITE}/AXS.svg`,
	sBCH: `${SYN_MARKET_ASSETS_SITE}/BCH.svg`,
	sBLUR: `${SYN_MARKET_ASSETS_SITE}/BLUR.svg`,
	sBNB: `${SYN_MARKET_ASSETS_SITE}/BNB.svg`,
	sCHF: `${SYN_MARKET_ASSETS_SITE}/CHF.svg`,
	sCRV: `${SYN_MARKET_ASSETS_SITE}/CRV.svg`,
	sDOGE: `${SYN_MARKET_ASSETS_SITE}/DOGE.svg`,
	sDOGE10PERP: `${SYN_MARKET_ASSETS_SITE}/DOGE.svg`,
	sDOT: `${SYN_MARKET_ASSETS_SITE}/DOT.svg`,
	sDYDX: `${SYN_MARKET_ASSETS_SITE}/DYDX.svg`,
	sEUR: `${SYN_MARKET_ASSETS_SITE}/EUR.svg`,
	sFIL: `${SYN_MARKET_ASSETS_SITE}/FIL.svg`,
	sFLOKI: `${SYN_MARKET_ASSETS_SITE}/FLOKI.svg`,
	sFLOW: `${SYN_MARKET_ASSETS_SITE}/FLOW.svg`,
	sFTM: `${SYN_MARKET_ASSETS_SITE}/FTM.svg`,
	sGBP: `${SYN_MARKET_ASSETS_SITE}/GBP.svg`,
	sGMX: `${SYN_MARKET_ASSETS_SITE}/GMX.svg`,
	sINJ: `${SYN_MARKET_ASSETS_SITE}/INJ.svg`,
	sINR: `${SYN_MARKET_ASSETS_SITE}/INR.svg`,
	sJPY: `${SYN_MARKET_ASSETS_SITE}/JPY.svg`,
	sKRW: `${SYN_MARKET_ASSETS_SITE}/KRW.svg`,
	sLDO: `${SYN_MARKET_ASSETS_SITE}/LDO.svg`,
	sLINK: `${SYN_MARKET_ASSETS_SITE}/LINK.svg`,
	sLTC: '/images/svg/currencies/LTC.svg',
	sPOL: '/images/svg/currencies/POL.svg',
	sNEAR: `${SYN_MARKET_ASSETS_SITE}/NEAR.svg`,
	sOP: `${SYN_MARKET_ASSETS_SITE}/OP.svg`,
	sPEPE: `${SYN_MARKET_ASSETS_SITE}/PEPE.svg`,
	sSHIB: `${SYN_MARKET_ASSETS_SITE}/SHIB.svg`,
	sSOL: `${SYN_MARKET_ASSETS_SITE}/SOL.svg`,
	sSUI: `${SYN_MARKET_ASSETS_SITE}/SUI.svg`,
	sTRX: `${SYN_MARKET_ASSETS_SITE}/TRX.svg`,
	sUNI: `${SYN_MARKET_ASSETS_SITE}/UNI.svg`,
	sUSD: `${SYN_MARKET_ASSETS_SITE}/USD.svg`,
	sWTI: `${SYN_MARKET_ASSETS_SITE}/OIL.svg`,
	sXAU: `${SYN_MARKET_ASSETS_SITE}/XAU.svg`,
	sXAG: `${SYN_MARKET_ASSETS_SITE}/XAG.svg`,
	sXMR: `${SYN_MARKET_ASSETS_SITE}/XMR.svg`,
	sXRP: `${SYN_MARKET_ASSETS_SITE}/XRP.svg`,
	KWENTA: '/images/svg/currencies/kwenta.svg',
	sSNX: `${SYN_MARKET_ASSETS_SITE}/SNXDark.svg`,
	tBTC: '/images/svg/currencies/WBTC.svg',
	SNXBTC: SNXBTC,
	SNXETH: SNXETH,
	SNXUSD: SNXUSD,
	SNXLINK: SNXLINK,
	SNXOP: SNXOP,
	sUSDC: '/images/svg/currencies/USDC.svg',
	USDx: '/images/svg/currencies/USDx.svg',
	ETH: `${SYN_MARKET_ASSETS_SITE}/ETH.svg`,
	WETH: `${SYN_MARKET_ASSETS_SITE}/ETH.svg`, // TODO: WETH icon
	USDC: '/images/svg/currencies/USDC.svg',
	USDC_BLUE: '/images/svg/currencies/USDC.svg',
	USDe: '/images/svg/currencies/USDe.svg',
	sUSDe: '/images/svg/currencies/USDe.svg',
	wSOL: `${SYN_MARKET_ASSETS_SITE}/SOL.svg`,
	sAERO: '/images/svg/currencies/AERO.svg',
	sPOPCAT: '/images/svg/currencies/POPCAT.svg',
	sEIGEN: '/images/svg/currencies/EIGEN.svg',
	sIO: '/images/svg/currencies/IO.svg',
	sMEW: '/images/svg/currencies/MEW.svg',
	sNOT: '/images/svg/currencies/NOT.svg',
	sPEOPLE: '/images/svg/currencies/PEOPLE.svg',
	sRENDER: '/images/svg/currencies/RENDER.svg',
	sSATS: '/images/svg/currencies/SATS.svg',
	sZRO: '/images/svg/currencies/ZRO.svg',
	wstETH: `${SYN_MARKET_ASSETS_SITE}/STETH.svg`,
	cbETH: '/images/svg/currencies/cbETH.svg',
	cbBTC: '/images/svg/currencies/cbBTC.svg',
	sPNUT: '/images/svg/currencies/PNUT.svg',
	sSLERF: '/images/svg/currencies/SLERF.svg',
	sDEGEN: '/images/svg/currencies/DEGEN.svg',
	sMORPHO: '/images/svg/currencies/MORPHO.svg',
	sVIRTUAL: '/images/svg/currencies/VIRTUAL.svg',
	sCHILLGUY: '/images/svg/currencies/CHILLGUY.svg',
	sSOLETH: '/images/svg/currencies/SOLETH.svg',
	sPENGU: '/images/svg/currencies/PENGU.svg',
	sFARTCOIN: '/images/svg/currencies/FARTCOIN.svg',
	sKMNO: '/images/svg/currencies/KMNO.svg',
	sTRUMP: '/images/svg/currencies/TRUMP.svg',
	sMELANIA: '/images/svg/currencies/MELANIA.svg',
	sS: '/images/svg/currencies/S.svg',
	sVELO: '/images/svg/currencies/VELO.svg',
	sVVV: '/images/svg/currencies/VVV.svg',
	sBERA: '/images/svg/currencies/BERA.svg',
}
