import type { UserOpResponse } from '@biconomy/account'
import {
	PerpsProvider,
	type SimulatedRequest,
	type SnxV2NetworkIds,
	type SnxV3NetworkIds,
} from '@kwenta/sdk/types'
import { isSimulatedRequest, simulatedRequestToTxRequest } from '@kwenta/sdk/utils'

import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Transaction } from 'types/accountAbstraction'
import { type Address, type Hash, isHash } from 'viem'

import { monitorAndAwaitTransaction, monitorAndAwaitUserOp } from 'state/app/helpers'
import { handleTransactionError } from 'state/app/reducer'
import { handleFetchError } from 'state/helpers'
import { FetchStatus, type ThunkConfig } from 'state/types'
import { serializeIsolatedMarginBalanceInfo } from 'utils/futures'
import logError from 'utils/logError'

import { setQueryStatus, updateAccountData } from '../reducer'
import { selectAccountContext, selectAccountContexts } from '../selectors'

import { selectCrossMarginAssetsForPrices } from '../snxPerpsV3/selectors'
import { selectOneClickTradingSelected } from './selectors'

export const fetchAccountMarginInfo = createAsyncThunk<void, PerpsProvider[], ThunkConfig>(
	'futures/fetchAccountMarginInfo',
	async (providers, { getState, dispatch, extra: { sdk } }) => {
		const contexts = selectAccountContexts(getState())
		for (const provider of providers) {
			try {
				const { network: networkId, accountId, wallet } = contexts[provider]
				if (!accountId || !networkId || !wallet) continue

				dispatch(setQueryStatus({ key: 'get_balance_info', status: FetchStatus.Loading, provider }))

				switch (provider) {
					case PerpsProvider.SNX_V3_BASE:
					case PerpsProvider.SNX_V3_ARB: {
						const assets = selectCrossMarginAssetsForPrices(getState())

						const {
							availableMargin,
							withdrawableMargin,
							requiredInitialMargin,
							requiredMaintenanceMargin,
							maxLiquidationReward,
							debt,
						} = await sdk.snxPerpsV3.getAccountMarginInfo(
							BigInt(accountId),
							assets,
							networkId as SnxV3NetworkIds
						)
						dispatch(
							updateAccountData({
								wallet,
								data: {
									provider: provider,
									account: accountId,
									network: networkId,
									marginInfo: {
										availableMargin: availableMargin.toString(),
										withdrawableMargin: withdrawableMargin.toString(),
										requiredInitialMargin: requiredInitialMargin.toString(),
										requiredMaintenanceMargin: requiredMaintenanceMargin.toString(),
										maxLiquidationReward: maxLiquidationReward.toString(),
										debt: debt.toString(),
									},
								},
							})
						)
						break
					}

					case PerpsProvider.SNX_V2_OP: {
						const balanceInfoSnxV2 = await sdk.snxPerpsV2.getSmartMarginBalanceInfo(
							wallet,
							accountId as Address,
							networkId as SnxV2NetworkIds
						)

						dispatch(
							updateAccountData({
								wallet,
								data: {
									provider: PerpsProvider.SNX_V2_OP,
									balanceInfo: serializeIsolatedMarginBalanceInfo({
										...balanceInfoSnxV2,
										totalMarginByMarket: {},
									}),
									network: networkId,
									account: accountId,
								},
							})
						)
						break
					}
				}

				dispatch(setQueryStatus({ key: 'get_balance_info', status: FetchStatus.Success, provider }))
			} catch (err) {
				handleFetchError(dispatch, 'get_balance_info', err, { provider })
			}
		}
	}
)

export const submitFuturesTransaction = createAsyncThunk<
	void,
	{
		request: Transaction | SimulatedRequest
		onSuccess?: VoidFunction
		onError?: (err: Error) => void
	},
	ThunkConfig
>(
	'futures/submitFuturesTransaction',
	async (
		{ request, onSuccess, onError },
		{ dispatch, getState, extra: { sdk, accountAbstractionFactory } }
	) => {
		const state = getState()
		const { provider, network } = selectAccountContext(state)
		const isOneClickReady = selectOneClickTradingSelected(state)
		const accountAbstraction = accountAbstractionFactory.getAccountAbstraction(provider)

		const isUsingOneClick = Boolean(accountAbstraction && isOneClickReady)

		const isSimulatedAction = isSimulatedRequest(request)

		let res: Hash | UserOpResponse

		try {
			if (isUsingOneClick) {
				res = await accountAbstraction!.sendTransactions({
					simulateTxs: isSimulatedAction ? [request] : [],
					readyTxs: isSimulatedAction ? [] : [request],
				})
			} else {
				res = await sdk.transactions.sendTransaction(
					isSimulatedAction ? simulatedRequestToTxRequest(request) : request,
					network
				)
			}

			if (typeof res === 'string' && isHash(res)) {
				await monitorAndAwaitTransaction(network, dispatch, res)
			} else {
				await monitorAndAwaitUserOp(dispatch, res)
			}
			onSuccess?.()
		} catch (err) {
			logError(err)
			dispatch(handleTransactionError({ message: err.message }))
			onError?.(err)
		}
	}
)
